import * as React from 'react';
import { Link, graphql, HeadProps } from 'gatsby';
import Header from '../components/Header';
import Footer from "../components/Footer"
import * as style from '../style/_index.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import Seo from "../components/Seo"

type DataProps = {
  site: {
    siteMetadata: {
      title: string;

      description: string;
    };
  };
};
const EventsPage = ({ data, data: { site } }: PageProps<DataProps>) => {
  return (
    <main>
      <Header></Header>
      <section className={style.sectionEventsWrap}>
        <div className={style.marginWrap}>
          <h2>Events</h2>
          <div className={style.gridWrap}>
            {data.allMarkdownRemark.nodes.map((node) =>
              node.frontmatter.category === Event ? (
                <div></div>
              ) : (
                <div className={style.contentWrap} key={node.id}>
                    <a href={node.fields.slug}>
                  <div className={style.image}>
                    <GatsbyImage
                      alt={node.frontmatter.title}
                      image={
                        node.frontmatter.thumbnail.childImageSharp
                          .gatsbyImageData
                      }
                    />
                  </div>
                    <div className={style.title}>{node.frontmatter.title}</div>
                    <div className={style.description}>
                      {node.frontmatter.date}
                    </div>
                  </a>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </main>
  );
};

export default EventsPage;
export const Head = () => <Seo title='イベント' />;
   

export const query = graphql`
  query EventsPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        html
        fields {
          slug
        }
        frontmatter {
          date
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 1280
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;
