// extracted by mini-css-extract-plugin
export var PCJLogo = "_index-module--PCJLogo--RwJyM";
export var PCJLogo2 = "_index-module--PCJLogo2--3oVLL";
export var canvasWrap = "_index-module--canvasWrap--frser";
export var contentWrap = "_index-module--contentWrap--ZzbBz";
export var description = "_index-module--description--638Mq";
export var descriptionWrap = "_index-module--descriptionWrap--PPcd7";
export var gridWrap = "_index-module--gridWrap--9W8py";
export var imageWrap = "_index-module--imageWrap--+7VAz";
export var link = "_index-module--link--vqFd4";
export var linkWrap = "_index-module--linkWrap--u+sBU";
export var logoWrap = "_index-module--logoWrap--S4W-+";
export var loop = "_index-module--loop--9BT77";
export var marginWrap = "_index-module--marginWrap--Y+XIG";
export var sectionEventsWrap = "_index-module--sectionEventsWrap--l4tde";
export var sectionGalleryWrap = "_index-module--sectionGalleryWrap--c14U-";
export var sectionLinksWrap = "_index-module--sectionLinksWrap--Ld+V-";
export var sectionRecipesWrap = "_index-module--sectionRecipesWrap--FTqJa";
export var sectionWrap = "_index-module--sectionWrap--3z0En";
export var textWrap = "_index-module--textWrap--lXrkT";
export var title = "_index-module--title--Go9VJ";
export var visualWrap = "_index-module--visualWrap--9L5B7";
export var youtubeWrap = "_index-module--youtubeWrap--v9kr3";